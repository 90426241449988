import { render, staticRenderFns } from "./OnboardingSubaccounts.vue?vue&type=template&id=d691564a&scoped=true"
import script from "./OnboardingSubaccounts.vue?vue&type=script&lang=js"
export * from "./OnboardingSubaccounts.vue?vue&type=script&lang=js"
import style0 from "./OnboardingPage.scss?vue&type=style&index=0&id=d691564a&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d691564a",
  null
  
)

export default component.exports