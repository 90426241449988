import { render, staticRenderFns } from "./OnboardingSend.vue?vue&type=template&id=cd4d8054&scoped=true"
import script from "./OnboardingSend.vue?vue&type=script&lang=js"
export * from "./OnboardingSend.vue?vue&type=script&lang=js"
import style0 from "./OnboardingPage.scss?vue&type=style&index=0&id=cd4d8054&prod&lang=scss&scoped=true&external"
import style1 from "./OnboardingSend.vue?vue&type=style&index=1&id=cd4d8054&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cd4d8054",
  null
  
)

export default component.exports